<!-- View de Funerarias Sucursales -->

<template>
  <div class="funes_M_view" v-if="schema"
       style="align-self:start">

    <v-dialog v-model="dialog" content-class="modal" persistent> 
        <template v-slot:activator="{on}">
        
          <!-- Botón para abrir la ventana -->
          <v-btn 
            v-on="accionRow.id>0? on : ''"
            v-on:click="accionRow.id>0? btra_Mto({ accion:0 }) : ''"
            v-bind="$cfg.btra_cfg.standard"
            title="Ver Funeraria Sucursal">
              <v-icon>{{'mdi-eye'}}</v-icon>
          </v-btn>   
        </template>

        <div class="contenedor centrado">
          <v-sheet :elevation="4">        
            <!-- Cabecera -->
            <div class="cabecera">
              <base_Header
                v-if="Entorno.header.header"       
                :Entorno="Entorno.header"
                @onEvent="dialog=false">
              </base_Header>                  
            </div>

            <!-- Controles del Mto -->
            <div class="columna contenedor" style="width:650px;">
              <div class="conflex ml-2">                 
                  <ctrlcontact                    
                    :schema="schema"
                    :name="schema.ctrls.name"
                    :edicion="false">
                  </ctrlcontact>

                  <div class="conflex mb-2">
                    <v-text-field                      
                      v-bind="$input"
                      v-model="schema.ctrls.grupo.value"
                      :label="schema.ctrls.grupo.label"
                      readonly>
                    </v-text-field>
                  </div>     

                  <div class="conflex">
                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.tlf1.value"
                      label="Teléfono 1"
                      readonly>
                    </v-text-field>
                     
                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.tlf2.value"
                      label="Teléfono 2"
                      readonly>
                    </v-text-field>

                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.tlf3.value"
                      label="Teléfono 3"
                      readonly>
                    </v-text-field>
                  </div>

                  <div class="conflex">
                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.fax1.value"
                      label="Fax 1"
                      readonly>
                    </v-text-field>

                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.fax2.value"
                      label="Fax 2"
                      readonly>
                    </v-text-field>
                  </div>
                </div>
             
                
                <div class="ma-2" v-if="app==1">
                  <v-textarea
                    v-bind="$textarea"
                    v-model="schema.ctrls.obs.value"
                    :label="schema.ctrls.obs.label"
                    no-resize
                    readonly>                  
                  </v-textarea>

                  <v-textarea
                    v-bind="$textarea"
                    v-model="schema.ctrls.obsjz.value"
                    :label="schema.ctrls.obsjz.label"
                    no-resize
                    readonly>                  
                  </v-textarea>
                </div>
              </div>   
            
        </v-sheet>
      </div>
    </v-dialog>    
  </div>
</template>


<script>
 
  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: {  
      base_Header,
      ctrlcontact
    },

    props: {      
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'funes_M_view',
        stName:'stMfunes_view',
       
        dialog:false,       
      };
    },

    
    
    // secuencia del componente:
    //  mixinMto:
    //        1 create()
    //             2   ini_data()=>funes_M  (Inicializo datos reactivos y lo necesario para cargar schema(API))
    //             3   this.schema = await ... (cargo schema });
    //             4   ini_SchemaCtrls()=>funes_M ( ya tengo el schema y sigo la secuencia con ya todo lo necesario)
    //                                 this.component_base="base_M"; (lanzo el componente base hijo)
    //                                                                 (elimino if schema del template, no hace falta)
    //             5 await this.acciones_Mto({ accion: this.accion });  
    //             6 se pinta el componente  

  
    methods: {

      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Funerarias Sucursales"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
              
       
      },


      // override fn que se ejecuta al dispararse el mto
      ini_component() {
        
      },


    }
  };
</script>
